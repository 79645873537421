import * as React from "react";

export const UnsupportedBrowser = (): React.JSX.Element => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
            }}
        >
            <div>We are sorry, but this browser is not supported.</div>
            <br />
            <div>Please use Chrome, Firefox or Edge to visit this page!</div>
            <br />
            <div>Thank you!</div>
        </div>
    );
};
